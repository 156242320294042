window.addEventListener("DOMContentLoaded", () => {
    const navigation = document.querySelector(".navigation");
    const navigationOverlay = document.querySelector(".nav-overlay");
    const entries = document.querySelectorAll(".navigation > .navigation__item:has(.item__dropdown)");
    const burger = document.querySelector(".nav-burger");

    burger?.addEventListener("click", () => {
        if (burger.classList.contains("back")) {
            back();
        } else if (burger.classList.contains("open")) {
            close();
        } else {
            open();
        }
    });

    // On initialise tous les éléments
    entries.forEach(entry => {
        initEntry(entry);
    });

    // Fonction de retour du menu
    function back() {
        const activeEntry = document.querySelector(".navigation__item.-active");
        const activeDropdown = activeEntry.querySelector(".item__dropdown.-active");
        const activeSubdropdowns = activeDropdown.querySelectorAll(".dropdown__subitems.-active");
        const columns = activeDropdown.querySelectorAll(".dropdown__column > div:has(.dropdown__subitem)");

        activeSubdropdowns.forEach(item => {
            item.classList.remove("-active");
        });

        columns.forEach(item => {
            const dropdownItem = item.querySelector(".dropdown__item");

            // On retire l'évent listener sinon on a des events en double
            dropdownItem.removeEventListener("click", initSubitemHandler);
        });

        activeEntry.classList.remove("-active");
        activeDropdown.classList.remove("-active");
        burger.classList.remove("back");
    }

    // Fonction de fermeture du menu
    function close() {
        burger.classList.remove("open");
        navigationOverlay.classList.remove("-active");
        navigation.classList.remove("-active");
        document.body.style.overflow = "auto";
    }

    // Fonction d'ouverture du menu
    function open() {
        burger.classList.add("open");
        navigationOverlay.classList.add("-active");
        navigation.classList.add("-active");
        document.body.style.overflow = "hidden";
    }

    // Fonction d'initialisation des entrées du menu
    function initEntry(entry) {
        entry.addEventListener("mouseover", initImages)
        const link = entry.querySelector(".nav-link");

        // Le prevent default ne fonctionne pas sur un élément encodé, alors il faut supprimer la classe .fbo puisque dans tous les cas cet élément n'est pas cliquable
        if (link.classList.contains("fbo") && window.matchMedia("(max-width: 1024px)").matches) {
            link.classList.remove("fbo")
        }

        link.addEventListener("click", event => initLink(event, entry));
    }

    // Lazyloading des images dans la nav
    function initImages(event) {
        const wrapper = event.target.closest(".navigation__item");
        const images = wrapper.querySelectorAll(".js-navLazyLoad");
        if (images.length > 0) {
            images.forEach(image => {
                const dataSrc = image.getAttribute("data-src");
                if (dataSrc) image.setAttribute("src", dataSrc);
                image.classList.remove("js-navLazyLoad");
            })
        }

        wrapper.removeEventListener("mouseover", initImages);
    }

    // Fonction d'initialisation des éléments
    function initLink(e, entry) {
        const dropdown = entry.querySelector(".item__dropdown");

        // Si l'élément a des sous-éléments on va les initialiser et empêcher la redirection
        if (dropdown) {
            if (window.matchMedia("(max-width: 1024px)").matches) {
                // On empêche la redirection
                e.preventDefault();
                entry.classList.add("-active");
                burger.classList.add("back");
                dropdown.classList.add("-active");
            }

            const items = dropdown.querySelectorAll(".dropdown__column > div:has(.dropdown__subitem)");

            items.forEach(item => {
                const dropdownItem = item.querySelector(".dropdown__item");
                const navLink = dropdownItem.querySelector(".nav-link")

                // Le prevent default ne fonctionne pas sur un élément encodé, alors il faut supprimer la classe .fbo puisque dans tous les cas cet élément n'est pas cliquable
                if (navLink.classList.contains("fbo")) {
                    navLink.classList.remove("fbo")
                }

                // On passe par un handler pour pouvoir supprimer l'event listener quand on revient en arrière dans le menu, permet d'éviter d'avoir des event en double
                dropdownItem.addEventListener("click", initSubitemHandler);
            });
        }
    }

    
    function initSubitemHandler(event) {
        const item = event.currentTarget.parentElement;
        initSubitem(item, event);
    }

    // Fonction d'initialisation des sous-éléments
    function initSubitem(item, e) {
        if (item.querySelectorAll(".dropdown__subitem").length > 0) {
            const subitemsDropdown = item.querySelector(".dropdown__subitems");
            e.preventDefault();
            subitemsDropdown.classList.toggle("-active");
        }
    }
});
