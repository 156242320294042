(function ($, window, document, undefined) {
    'use strict';
    var input,
        inputTerms, termsArray, prefix, terms, results, sortedResults, suggestHover;
    var acpDNS = '';
    var acpLabel = 'shortLabel';
    var acpPhoto = 'photo';
    var acpPhotoPrefix = '';
    var acpPhotoSuffix = '';
    var acpShopName = 'ShopName';
    var acpPageLabel = 'label';
    var acpCornerLabel = 'label';
    var acpCornerUrl = 'url';
    var productData = [];
    var pageData = [];
    var shopData = [];
    var cornerData = [];
    var videoPlayers = [];
    var pageLoadedTimestamp = 0;

    // nom de librairie à modifier
    Foundation.libs.common = {
        name: 'common',

        version: '1.0.0',

        settings: {
            callback: function () { }

        },

        timerPanierDrop: null,

        init: function (scope, method, options) {
            // initialisation à conserver
            Foundation.inherit(this, '');
            var self = this;

            // votre code ici
            /* // exemple
            if (self.S("#" + this.settings.id).length === 0)
                self.S("#main-content", this.scope).append("<span id='" + this.settings.id + "'></span>");
            */

            // à conserver
            this.bindings(method, options);
        },
        isTouch: (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0)),
        initFormulaire: function () {
            $.validator.addMethod(
                "regex",
                function (value, element, regexp) {
                    var re = new RegExp(regexp);
                    return this.optional(element) || re.test(value);
                },
                "Vérifiez votre saisie."
            );
        },
        updateAccount: function () {
            //refresh des elements du compte dans le header
            $.ajax({
                url: '/Navigation/MonCompte',
                data: { isSticky: false },
                success: function (result) {
                    $('#span-account').html(result);
                }
            });
            $.ajax({
                url: '/Navigation/MonCompte',
                data: { isSticky: true },
                success: function (result) {
                    $('#span-account-sticky').html(result);
                }
            });
            $.ajax({
                url: '/Navigation/MonCompteSurvol',
                data: {},
                success: function (result) {
                    $('#span-account-survol').html(result);
                }
            });


        },
        updateArticlesInCart: function () {
            $.ajax({
                url: '/Navigation/ArticlesInCart',
                data: {},
                success: function (result) {
                    $(".span-cart-articles").html('');

                    $('#cart-pictogram span:last-child').remove();
                    $('#cart-pictogram').append(result);
                }
            });
        },
        updateWishlist: function () {
            $.ajax({
                url: '/Navigation/BijouxPreferes',
                data: { isSticky: true },
                success: function (result) {
                    $(".wishlist-produits").html('');
                    $("#span-wishlist-sticky").append(result);
                }
            });
            $.ajax({
                url: '/Navigation/BijouxPreferes',
                data: { isSticky: false },
                success: function (result) {
                    $(".wishlist-produits").html('');
                    $("#span-wishlist").append(result);
                }
            });
        },
        endsWith: function (str, suffix) {
            return str.indexOf(suffix, str.length - suffix.length) !== -1;
        },
        startsWith: function (str, prefix) {
            return str.indexOf(prefix) === 0;
        },
        dosearch: function (element) {
            var $input = $(element);
            if ($input.val().trim().length > 0
                && $input.val().trim() != undefined
                && $input.val().trim() != null
                && $input.val().trim()) {
                // Il faut échapper tous les caractères non alphanumériques (sauf espaces)
                // -> Ceci afin d'éviter une erreur au niveau de la configuration 
                // du serveur qui refuse certains caractères spéciaux (&...)

                // Certains navigateurs(<=IE11, Webview android) ne supportent pas la fonction normalize, 
                // On effectue différement pour ces cas
                var inputToEscape;
                // On verifie si c'est du IE <= 11 
                if (typeof (String.prototype.normalize) !== "function") {
                    // Tableau associatif des caractères accentués avec leur équivalent sans accent
                    var tabCar = { "à": "a", "á": "a", "â": "a", "ã": "a", "ä": "a", "å": "a", "ò": "o", "ó": "o", "ô": "o", "õ": "o", "ö": "o", "ø": "o", "è": "e", "é": "e", "ê": "e", "ë": "e", "ç": "c", "ì": "i", "í": "i", "î": "i", "ï": "i", "ù": "u", "ú": "u", "û": "u", "ü": "u", "ÿ": "y", "ñ": "n" };
                    var reg = /[àáäâèéêëçìíîïòóôõöøùúûüÿñ]/gi;

                    inputToEscape = $input.val().trim().replace(reg, function () { return tabCar[arguments[0].toLowerCase()] }).toLowerCase();
                }
                else {

                    // normalize('NFD') encode la chaîne de caractères de manière
                    // à décomposer les caractères "composés" en sa lettre et son diacritique (ex: le caractère "è" devient les caractères "e" + "`")
                    // replace(/[\u0300-\u036f]/g, "") supprime les diacritiques
                    inputToEscape = $input.val().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
                }

                inputToEscape = inputToEscape.replace("œ", "oe");

                // replace(/\W/gi, " ") supprime les caractères non alphanumériques et remplace par un espace
                // remplacement par [^A-Za-z0-9,] pour inclure la virgule : si on tape 0,5 carat il faut que ça fonctionne
                inputToEscape = inputToEscape.replace(/[^A-Za-z0-9,]/gi, " ").trim();

                window.location.href = "/Achat/" + encodeURIComponent(inputToEscape) + "?search=" + encodeURIComponent(inputToEscape);
            }
        },
        search: function (element) {
            inputTerms = element.value.toLowerCase();

            if (window.matchMedia("(max-width: 640px)").matches) {

                $(".search_wrapper").addClass("autocomplete-actived");

            }

            $(document).foundation('common', 'callSearch');
        },
        searchAfterTyping: function () {
            inputTerms = $('#searchBox-medium, #searchBox').val().toLowerCase();

            if (window.matchMedia("(max-width: 640px)").matches) {

                $(".search_wrapper").addClass("autocomplete-actived");
            }

            $(document).foundation('common', 'callSearch');
        },
        callSearch: function (goIfNoResults) {
            results = new Array();
            results[0] = new Array();
            results[1] = new Array();
            termsArray = inputTerms.split(' ');
            prefix = ''; //termsArray.length === 1 ? '' : termsArray.slice(0, -1).join(' ') + ' ';
            terms = ''; //termsArray[termsArray.length - 1].toLowerCase();

            acpDNS = $('#acpDNS').val()
            $.ajax({
                //dataType: "html",
                jsonpCallback: 'success',
                crossDomain: true,
                url: acpDNS,
                data: {
                    expression: inputTerms
                },
                success: function (data) {
                    // Si on a un résultat on retire la classe qui masque l'acp
                    if (data._SearchAutocomplete)
                        $('#autocomplete-parent').removeClass('hidden');

                    // Si on a aucun résultat et qu'on indique qu'on renvoie vers la page de recherche
                    if (data.IsNoResults && goIfNoResults) {
                        // On cache l'acp pour ne pas avoir le OUPS derrière le loader
                        $("#autocomplete-parent").addClass("hidden");
                        $(".search_wrapper").removeClass("autocomplete-actived");

                        // On lance la recherche qui renverra vers la page 
                        $(document).foundation('common', 'dosearch', $("#searchBox"));
                    }
                    else {
                        // On écrit le résultat
                        $('#autocomplete-parent').html(data._SearchAutocomplete);
                    }
                }
            });
        },
        evaluateResults: function (results) {
            console.log('ici');
            if (results.length > 0) { // 0 && inputTerms.length > 0 && terms.length !== 0) {
                //sortedResults = results.sort(sortResults);
                $(document).foundation('common', 'appendResults');
            }
            else if (inputTerms.length > 0 && terms.length !== 0) {
                $(document).foundation('common', 'clearResults');
            }
            else if (inputTerms.length !== 0 && terms.length === 0) {
                return;
            }
            else {
                $(document).foundation('common', 'clearResults');
            }
        },
        sortResults: function () {
            if (a.indexOf(terms) < b.indexOf(terms)) return -1;
            if (a.indexOf(terms) > b.indexOf(terms)) return 1;
            return 0;
        },
        appendResults: function () {
            $('#autocomplete-parent').html('');
            $('#autocomplete-parent').removeClass('hidden');
        },
        clearResults: function () {
            var $divNavAutoComplete = $('#autocomplete-parent');
            if (typeof ($divNavAutoComplete) !== "undefined") {
                $divNavAutoComplete.html('');
                $divNavAutoComplete.addClass('hidden');
            }
        },
        getPagesResult: function (data) {
            pageData = [];

            if (data && data.page) {
                data.page.forEach(function (item, index) {
                    pageData.push({
                        text: item.highlight[acpPageLabel][0],
                        val: item[acpPageLabel],
                        url: item['url'],
                        origin: "pageData"
                    })
                });
            }

            return pageData;
        },
        // Modifie les données de tc_vars, *sans* relancer le tag TagCommander
        refreshMasterTagData: function (data, keepMerchActions) {
            if (typeof (urlTagToRefresh) != "undefined" && typeof (tc_vars) != "undefined" && typeof (data) != "undefined" && data != "") {
                if (keepMerchActions === true) {
                    var merch_actions = tc_vars.merch_actions;

                    tc_vars = JSON.parse(data);
                    tc_vars.merch_actions = merch_actions;
                } else {
                    tc_vars = JSON.parse(data);
                }
            }
        },
        // Relance l'exécution du tag TagCommander (on sous-entend que les données du masterTag sont à jour)
        refreshMasterTag: function (callback) {
            if (typeof (urlTagToRefresh) != "undefined") {
                if (typeof (callback) != "undefined") {
                    $.getScript(urlTagToRefresh, callback);
                }
                else {
                    $.getScript(urlTagToRefresh);
                }
            }
        },
        shareLinkOnFacebook: function (elem) {
            // Partage Facebook
            // Attributs possibles : 
            //  . data-url="" 
            //  . data-picture-url="" 
            //  . data-title="" 
            //  . data-caption=""   // "MATY.COM" par défaut si non défini
            //  . data-description=""
            var page = elem.attr("href");
            var _link = elem.attr("data-url");
            var _picture = elem.attr("data-picture-url");
            var _title = elem.attr("data-title");
            var _caption = elem.attr("data-caption");
            var _description = elem.attr("data-description");

            if (typeof (_link) == "undefined") _link = "";
            if (typeof (_picture) == "undefined") _picture = "";
            if (typeof (_title) == "undefined") _title = "";
            if (typeof (_caption) == "undefined") _caption = "MATY.COM";
            if (typeof (_description) == "undefined") _description = "";

            _link = _link.replace("'", "\'");
            _picture = _picture.replace("'", "\'");
            _title = _title.replace("'", "\'");
            _caption = _caption.replace("'", "\'");
            _description = _description.replace(new RegExp("&nbsp;", 'g'), " ").replace(new RegExp("\"", 'g'), "'");;

            //FB.ui({
            //    method: 'feed',
            //    link: _link.replace("'", "\'"),
            //    picture: _picture.replace("'", "\'"),
            //    name: _title.replace("'", "\'"),
            //    caption: _caption.replace("'", "\'"),
            //    description: _description.replace("'","\'").replace("&nbsp;"," ");
            //});
            var url = "https://www.facebook.com/dialog/feed?app_id=" + social_fb_app_id + "&link=" + encodeURIComponent(_link) + "&picture=" + encodeURIComponent(_picture) + "&name=" + encodeURIComponent(_title) + "&caption=" + encodeURIComponent(_caption) + "&description=" + encodeURIComponent(_description) + "&redirect_uri=" + encodeURIComponent(page);

            //var p = window.open(url, '', 'width=1024,height=600,toolbar=0,resizable=1,menubar=0');
            var p = window.open(url);
            if (!p)
                window.location = url;

        },
        followOnTwitter: function (elem) {
            var page = elem.attr("href");
            var url = "https://twitter.com/intent/follow?original_referer=" + encodeURIComponent(page) + "&region=follow_link&screen_name=MatyBijoutier&tw_p=followbutton&variant=2.0";

            var p = window.open(url);
            if (!p)
                window.location = url;

        },
        shareLinkOnTwitter: function (elem) {
            var page = elem.attr("href");
            var url = "https://twitter.com/intent/tweet?url=" + encodeURIComponent(page);

            var p = window.open(url);
            if (!p)
                window.location = url;

        },
        shareLinkOnPinterest: function (elem) {
            var page = elem.attr("href");
            var _picture = elem.attr("data-picture-url");
            var _description = elem.attr("data-description");

            _picture = _picture.replace("'", "\'");
            _description = _description.replace(new RegExp("&nbsp;", 'g'), " ").replace(new RegExp("\"", 'g'), "'");

            var url = "http://pinterest.com/pin/create/button/?url=" + encodeURIComponent(page) + "&media=" + encodeURIComponent(_picture) + "&description=" + encodeURIComponent(_description);

            var p = window.open(url);
            if (!p)
                window.location = url;
        },
        scrollToChild: function (aid) {
            var aTag = $("." + aid);
            if (aTag != typeof ("undefined") && aTag != "" && aTag.offset() != null)
                $('html,body').animate({ scrollTop: aTag.offset().top }, 'slow');
        },
        getFileName: function (input) {
            if (input != null && typeof (input) != "undefined") {
                if (input.is(':file') && input.val().length > 0) {
                    return String(input.val().replace(/C:\\fakepath\\/i, ''));
                }
            }

            return null;
        },
        getSizeUploadedFile: function (input) {
            if (input != null && typeof (input) != "undefined") {
                if (input.is(':file') && input.val().length > 0) {
                    return input[0].files[0].size;
                }
            }

            return null;
        },
        getPrevisualisationPolice: function (elem) {
            $.ajax({
                cache: false,
                async: true,
                type: "POST",
                data: {
                    codepolice: elem.attr('data-codepolice'),
                    texte: $('#Texte').val(),
                    ReferenceProduit: elem.attr('data-reference'),
                    StatutGravure: elem.attr('data-statut'),
                    CodeEmplacement: elem.attr('data-codeemplacement'),
                    IdCommandeLigne: elem.attr('data-idligne')
                },
                url: "/Product/GetPreviewGravureDecoupe",
                success: function (response) {
                    if (typeof (response) != "undefined") {
                        if (response.IsValid) {
                            $('#rendu-gravure').html(response.Html);
                        }
                    }
                },
                error: function (response) {
                }
            });
        },
        isInt: function (n) {
            return Number(n) === n && n % 1 === 0;
        },
        isFloat: function (n) {
            return Number(n) === n && n % 1 !== 0;
        },
        clearLightCart: function () {
            $(document).foundation('common', 'updateArticlesInCart');

            var survolWrapper = $(".mon-panier-drop");

            survolWrapper.html("");
        },
        // ANIMATION TOGGLE SLIDE MAISON
        toggleAnimation: function (obj) {
            var $elems = obj.contents;
            var callback = obj.callback;
            var callbackParameter = obj.callbackParameter;

            if (typeof (callback) != "undefined") {
                if (typeof (callbackParameter) != "undefined") {
                    var animEffect = function () {
                        return $elems.animate({
                            height: "toggle",
                            'padding-top': 'toggle',
                            'padding-bottom': 'toggle',
                            opacity: "toggle"
                        });
                    };

                    $.when(animEffect()).done(function () { // Permet de lancer le callback une seule fois quand toutes les animations sont terminées
                        callback(callbackParameter)
                    });
                }
                else {
                    var animEffect = function () {
                        return $elems.animate({
                            height: "toggle",
                            'padding-top': 'toggle',
                            'padding-bottom': 'toggle',
                            opacity: "toggle"
                        });
                    };

                    $.when(animEffect()).done(callback);    // Permet de lancer le callback une seule fois quand toutes les animations sont terminées
                }
            } else {
                $elems.animate({
                    height: "toggle",
                    'padding-top': 'toggle',
                    'padding-bottom': 'toggle',
                    opacity: "toggle"
                });
            }
        },
        showPreloader: function () {
            $("#preloader").show();
        },
        hidePreloader: function () {
            $("#preloader").hide();
        },
        initYTPlayers: function () {
            var tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

            window.onYouTubeIframeAPIReady = function () {
                $(document).foundation('common', 'initYTTracking');
            };
        },
        initYTTracking: function () {
            var playerWrappers = $('.yt-player');

            playerWrappers.each(function () {
                var idWrapper = $(this).attr("id");
                YT.get(idWrapper).addEventListener('onStateChange', window.onYTPlayerStateChange);
            });

        },
        getQueryStringValue: function (field, url) {
            var href = url ? url : window.location.href;
            var reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
            var string = reg.exec(href);
            return string ? string[1] : null;
        },
        // Tracking des liens dans les zones de merch
        initTrackMerch: function () {
            if (typeof (tc_vars) != "undefined" && tc_vars.env_template != 'LANDING' && tc_vars.env_template != 'PRODUCT_SHEET') {
                return;
            }

            // On recherche chaque zones de merch, 
            $(".zone-merch-wrapper").each(function (index, item) {
                var currentZone = item.getAttribute("data-zone");
                var trackedObjects = [];

                if (currentZone != null) {
                    // On recherche tous les liens de la zone de merch
                    trackedObjects.push({ group: 'links', items: item.getElementsByTagName("a") });
                }

                // Application des comprotements spécifiques à chaque groupe de liens
                for (var i = 0; i < trackedObjects.length; i++) {
                    for (var j = 0; j < trackedObjects[i].items.length; j++) {
                        trackedObjects[i].items[j].setAttribute("data-zone", currentZone); //On injecte dans leur data le nom de la zone de merch, pour l'avoir à dispo sur l'événement click
                    }
                }
            });
        },
        OnSucceNewsletterSuscribe: function (result) {
            $.ajax({
                url: '/Navigation/SetCookieNewsletter',
            });
            // On écrit le contenu de la modal
            $('#newsletter-reveal-modal').html(result._NewsLetterSubscription);

            $("#newsletter-button-wrapper").remove();

            LazyLoad.reset();
            Foundation.libs.lazyloadvideos.initGlobal();

            Foundation.libs.common.hidePreloader();
        },

        decodeHTMLEntities: function (text) {
            return $("<textarea/>").html(text).text();
        },
        initCarousel: function () {

            $('.owl-carousel.owl__cards').owlCarousel({
                navText: [
                    "",
                    "",
                ],
                loop: true,
                margin: 10,
                touchDrag: true,
                lazyLoad: true,
                center: false,
                mergeFit: false,
                autoWidth: true,
                responsive: {
                    0: {
                        items: 1,
                        center: true,
                        dots: true
                    },
                    641: {
                        nav: true,
                        dots: true,
                    },
                },
            });
        },
        events: function () {
            var self = this;
            var S = this.S;

            $(this.scope)
                .ready(function (event) {
                    // On enregistre le timestamp quanad la librairie est chargée. Cela permet de calculer une durée en millisecondes, entre le chargement de la librairie et un clic (utilisé dans la méthode trackMerch)
                    pageLoadedTimestamp = Math.floor(Date.now());
                    $(document).foundation('common', 'initTrackMerch');

                    $("#content-overlay").css("display", "none");

                    // Autocomplétion
                    var typingTimer;
                    var doneTypingInterval = 500;  //time in ms

                    //on keyup, start the countdown
                    $('#searchBox-medium, #searchBox').on('keyup', function () {
                        clearTimeout(typingTimer);
                        typingTimer = setTimeout(Foundation.libs.common.searchAfterTyping, doneTypingInterval);
                    });

                    //on keydown, clear the countdown 
                    $('#searchBox-medium, #searchBox').on('keydown', function () {
                        clearTimeout(typingTimer);
                    });

                    $('#searchBox-medium, #searchBox').on('click', Foundation.utils.throttle(function (event) {
                        $(document).foundation('common', 'search', this);
                    }, 300, true));

                    $(document).foundation('common', 'initFormulaire');

                    // Animation du bouton de la modal newsletter
                    const nwlButton = $("#newsletter-button-wrapper");

                    if (nwlButton.hasClass("-newsletter-hidden")) {
                        setTimeout(() => {
                            if (document.getElementById("tc-privacy-wrapper") === null) {
                                const buttonWrapper = document.getElementById("newsletter-button-wrapper");
                                buttonWrapper.classList.remove("-newsletter-hidden");

                                setTimeout(() => {
                                    const pin = document.getElementById("newsletter-pin");
                                    pin.classList.remove("-pin-hidden");
                                }, 2000);

                                console.log("set cookie");

                                $.ajax({
                                    url: '/Navigation/SetCookieNewsletterButtonAnimation',
                                });
                            }
                        }, 5000);
                    }

                    // Déclenchement lazyloading vidéos
                    Foundation.libs.lazyloadvideos.initGlobal()
                })
                .on('submit', '#form-inscription-newsletter', function (event) {
                    event.preventDefault();
                    var email = $('#input-email-newsletter').val();

                    $(this).validate();
                    if ($(this).valid()) {
                        $.getJSON('/Navigation/InscriptionNewsletter', { email: email }, function (data) {
                            if (data.IsValid) {
                                window.location = data.Redirection;
                            }
                            else {
                                $('.footer-alert-box').css("display", "block");
                                $('.footer-alert-box').removeClass("success");
                                $('.footer-alert-box').addClass("error");
                                $('.footer-alert-box').html("<label class='error'>" + data.Erreurs[0] + "</label>");
                            }
                        });
                    }
                })
                .on('keyup load', ".m-inputWrapper.-counter input", function (e) {
                    var $targetInput = $(e.currentTarget);
                    var $targetSpan = $targetInput.parents('.m-inputWrapper').find('.m-inputWrapper__counter span');
                    var maxLength = parseInt($targetInput.attr('maxlength'));
                    var inputLength = $targetInput.val().replace(/\n/g, "\r\n").length;
                    var text = maxLength - inputLength;

                    if (text < 2)
                        text = text + " caractère restant";
                    else
                        text = text + " caractères restants";

                    $targetSpan.text(text);
                })
                .on('keydown', '#searchBox-medium, #searchBox', function (event) {
                    if (event.keyCode == '13') {
                        $(document).foundation('common', 'dosearch', this);
                    }
                })
                .on('keydown', '.searchBox-sticky', function (event) {
                    if (event.keyCode == '13') {
                        var $input = $('.searchBox-sticky');
                        $(document).foundation('common', 'dosearch', $input[0]);
                    }
                })
                .on('mouseover', '#searchResults-medium, #searchResults', function (event) {
                    suggestHover = true;
                })
                .on('mouseout', '#searchResults-medium, #searchResults', function (event) {
                    suggestHover = false;
                })
                .on('mouseenter', '#shopping-cart-hover', function (event) {
                    var survolWrapper = $(".mon-panier-drop");

                    if (survolWrapper.children().length === 0) {
                        $.ajax({
                            url: '/Navigation/ShowLightCart',
                            success: function (result) {
                                if (result.IsValid) {
                                    survolWrapper.html(result._LightCart);
                                }
                            }
                        });
                    }
                })
                .on('blur', '#searchBox-medium, #searchBox', function (event) {
                    if (!suggestHover)
                        $(document).foundation('common', 'clearResults');
                })
                .on('click', '#button-search-sticky', function (event) {
                    event.preventDefault();

                    var $input = $('.searchBox-sticky');
                    $(document).foundation('common', 'dosearch', $input[0]);
                })
                .on('click', '#button-search-medium', function (event) {
                    event.preventDefault();

                    var $input = $('#searchBox-medium');
                    $(document).foundation('common', 'dosearch', $input[0]);
                })
                .on('click', '#button-search', function (event) {
                    event.preventDefault();

                    var $input = $('#searchBox');
                    $(document).foundation('common', 'dosearch', $input[0]);
                })
                .on('click', '.produit-item a .wishlist-action-wrapper .ajouter-bijoux-preferes, .ajouter-bijoux-preferes, .ajouter-bijoux-preferes2, .ajouter-bijoux-soldes, .ajouter-bijoux-soldes2, .produit-item a .wishlist-action-wrapper .ajouter-bijoux-preferes-soldes', function (event) {
                    event.preventDefault();
                    event.stopPropagation()
                    event.stopImmediatePropagation();

                    var $this = $(this);
                    var ref = $(this).attr('data-reference');
                    var variante = $(this).attr('data-variant');
                    var url = window.location.pathname;

                    if (!$(this).hasClass('bijou-ajoute')) {

                        // Tracking via TagCo
                        if (typeof (tc_vars) !== "undefined") {
                            // Si on est sur une page liste
                            if (typeof (tc_vars.list_products) === "object") {
                                var product = tc_vars.list_products.filter(function (p) {
                                    return p.product_id === ref;
                                })[0];

                                $(document).foundation('mastertag', 'track_tc_event',
                                    {
                                        obj: this,
                                        trigger: 'clic_favoris',
                                        options: {
                                            id: "clic_favoris",
                                            category: 'favoris',
                                            action: product.product_name + '|' + ref,
                                            label: product.product_url,
                                            value: product.product_unitprice_ati,
                                            product_brand: product.product_trademark,
                                            //product_cat1: product.product_cat1,
                                            //product_cat2: product.product_cat2,
                                            //product_cat3: product.product_cat3,
                                            product_category: product.product_category,
                                            product_discount_tf: product.product_discount_tf,
                                            product_discount_ati: product.product_discount_ati,
                                            product_gravable: product.product_gravable,
                                            product_id: product.product_id,
                                            product_name: product.product_name,
                                            product_promo: product.product_promo,
                                            product_promo_label: product.product_promo_label,
                                            product_quantity: product.product_quantity,
                                            product_rating: product.product_rating,
                                            product_size: product.product_size,
                                            product_trademark: product.product_trademark,
                                            product_unitprice_ati: product.product_unitprice_ati,
                                            product_unitprice_tf: product.product_unitprice_tf
                                        }
                                    });
                            }
                            else { // Sinon une fiche produit
                                $(document).foundation('mastertag', 'track_tc_event',
                                    {
                                        obj: this,
                                        trigger: 'clic_favoris',
                                        options: {
                                            id: "clic_favoris",
                                            category: 'favoris',
                                            action: tc_vars.product_name + '|' + ref,
                                            label: tc_vars.product_url,
                                            value: tc_vars.product_unitprice_ati,
                                            product_brand: tc_vars.product_trademark,
                                            product_cat1: tc_vars.product_cat1,
                                            product_cat2: tc_vars.product_cat2,
                                            product_cat3: tc_vars.product_cat3,
                                            product_category: tc_vars.product_category,
                                            product_discount_tf: tc_vars.product_discount_tf,
                                            product_discount_ati: tc_vars.product_discount_ati,
                                            product_gravable: tc_vars.product_gravable,
                                            product_id: tc_vars.product_id,
                                            product_name: tc_vars.product_name,
                                            product_promo: tc_vars.product_promo,
                                            product_promo_label: tc_vars.product_promo_label,
                                            product_quantity: tc_vars.product_quantity,
                                            product_rating: tc_vars.product_rating,
                                            product_size: tc_vars.product_size,
                                            product_trademark: tc_vars.product_trademark,
                                            product_unitprice_ati: tc_vars.product_unitprice_ati,
                                            product_unitprice_tf: tc_vars.product_unitprice_tf
                                        }
                                    });
                            }
                        }

                        Foundation.libs.common.showPreloader();

                        $.getJSON('/Wishlist/AddInWishlist', { reference: ref, variante: variante, url: url }, function (response) {
                            if (response.IsValid) {
                                $this.toggleClass('bijou-ajoute -active');
                                $(".wishlist-produits").html('');

                                $('#wishlist-pictogram span:last-child').remove();
                                $("#wishlist-pictogram").append(response.NumberInPicto);

                                $this.prop('title', 'Retirer de mes favoris');
                            }
                        }).always(function () { return false; });

                        Foundation.libs.common.hidePreloader();

                        return false;
                    }
                    else {
                        Foundation.libs.common.showPreloader();

                        $.getJSON('/Wishlist/RemoveInWishlistFromSelectionProduit', { reference: ref, variante: variante }, function (response) {
                            $this.toggleClass('bijou-ajoute -active');
                            $(".wishlist-produits").html('');

                            $('#wishlist-pictogram span:last-child').remove();
                            if (!response.NumberInPicto.includes(">0<")) {

                                $("#wishlist-pictogram").append(response.NumberInPicto);
                            }


                            $this.prop('title', 'Ajouter à mes favoris');

                            Foundation.libs.common.hidePreloader();
                        }).always(function () { return false; });

                        return false;
                    }
                    return false;
                })
                .on('click', '.produit-item', function (event) {
                    if (event.target != event.currentTarget) {
                        event.stopPropagation();
                    }
                })
                .on('click', '.social-share-facebook', function (event) {
                    event.preventDefault();
                    $(document).foundation('common', 'shareLinkOnFacebook', $(this));
                })
                .on('click', '.social-share-twitter', function (event) {
                    event.preventDefault();
                    $(document).foundation('common', 'shareLinkOnTwitter', $(this));
                })
                .on('click', '.social-fsocial-follow-twitter', function (event) {
                    event.preventDefault();
                    $(document).foundation('common', 'followOnTwitter', $(this));
                })
                .on('click', '.social-share-pinterest', function (event) {
                    event.preventDefault();
                    $(document).foundation('common', 'shareLinkOnPinterest', $(this));
                })
                .on('click', '.load-page-in-popin', function (event) {
                    event.preventDefault();
                    var urlContent = $(this).attr('data-content');
                    if (urlContent != null && urlContent != '' && typeof (urlContent) != 'undefined') {
                        var content = "";
                        var jqxhr = $.get(urlContent, function (data) {
                            //$('#popin-informations #title').html('MOT DE PASSE OU IDENTIFIANT OUBLIÉ ?');
                            $('#popin-informations #infos').html(data);
                            $('#popin-informations').foundation('reveal', 'open');
                        });
                    }
                })
                .on('click', '.pseudo-link', function (event) {
                    var urlContent = this.attributes["data-href"];

                    if (urlContent != null && urlContent != '' && typeof (urlContent) != 'undefined' && urlContent.value != '' && !$(this).hasClass("fbo")) {
                        window.location.href = urlContent.value;
                    }
                })
                .on('click', '.social-share-facebook', function (event) {
                    event.preventDefault();
                    $(document).foundation('common', 'shareLinkOnFacebook', $(this));
                })
                .on('click', '.apercu-police', function (event) {
                    event.preventDefault();
                    //(codePolice, texte, reference, idLigne, codeEmplacement, statutGravure, setResult)
                    $(document).foundation('common', 'getPrevisualisationPolice', $(this));
                })
                // DE PREFERENCE NE PAS UTILISER LA CLASSE "OBFUSCATION" AU CAS OU GOOGLE LA DETECTE
                // Je laisse le JS s'exécuter au cas où il en trainerait une
                .on('click', '.obfuscation', function (event) {
                    event.preventDefault();
                    var urlContent = this.attributes["data-href"];

                    var urlDecode = decodeURIComponent(window.atob(urlContent.value));

                    window.location.href = urlDecode;
                })
                // UTILISEZ PLUTOT CELLE LA
                .on('click', '.fbo', function (event) {
                    event.preventDefault();
                    var urlContent = this.attributes["data-href"];
                    var urlDecode = decodeURIComponent(window.atob(urlContent.value));
                    window.location.href = urlDecode;
                })
                // CLICK SUR PLAYER VIDEO (PL, FP, etc.)
                .on('click', '.video-content', function (e) {
                    $('#ModalReveal').addClass("video-nomarge");
                    $('#ModalReveal').html($(e.currentTarget).attr('data-href'));
                    $('#ModalReveal').foundation('reveal', 'open');
                })
                // FERMETURE POPIN (VIDEO)
                .on('closed', '#ModalReveal', function (e) {
                    if ($('#ModalReveal').hasClass("video-nomarge")) {
                        // On vire la classe pour ne pas flinguer les marges pour les autres utilisations de cette même popin
                        $('#ModalReveal').removeClass("video-nomarge");
                        $('#ModalReveal').html('');
                    }
                })
                .on('click', '.allEngineProducts', function (event) {
                    var $input = $('#searchBox');
                    $(document).foundation('common', 'dosearch', $input[0]);
                })
                .on('click', '.m-autocomplete__close', function (event) {
                    $(document).foundation('common', 'clearResults');
                })
                // CLICK SUR LIEN DANS LA POPIN DES RESULTATS DU MOTEUR DE RECHERCHE (ACP)
                .on('click', '.search-result-link', function (event) {
                    $("#autocomplete-parent").addClass("hidden");
                })
                // CLICK SUR LIEN TOP RECHERCHE DANS LA POPIN DES RESULTATS VIDE DU MOTEUR DE RECHERCHE OU LIENS (ACP) 
                .on('click', '.search-empty-result-link', function (event) {
                    event.preventDefault();

                    var $input = $('#searchBox');

                    $input[0].value = ($(this).html());

                    inputTerms = $(this).html();

                    $("#autocomplete-parent").addClass("hidden");

                    $(document).foundation('common', 'callSearch', true);
                })
                // CLICK SUR UNE MARQUE CROSSFILTERMARQUE
                .on('click', '.crossFilterMarque', function (event) {
                    event.preventDefault();

                    var expression = this.attributes["data-expression"];
                    var brand = this.attributes["data-brand"];

                    var $input = $('#searchBox');

                    var newTerms = $input[0].value + "?" + expression.value;

                    inputTerms = newTerms;

                    $(document).foundation('common', 'callSearch');
                })
                // CLICK SUR LA CROIX DE FERMETURE DU MOTEUR DE RECHERCHE (ACP)
                .on('click', '.autocomplete-close', function (event) {
                    $("#searchBox").val("");
                    $(".search_wrapper").removeClass("autocomplete-actived");
                    $("#autocomplete-parent").addClass("hidden");
                })
                // CLICK SUR LIEN POUR REJOINDRE LE PROGRAMME FID
                .on('click', '.rejoin-loyalty', function (e) {
                    e.preventDefault();

                    var origin = $(this).data("origin");

                    $("#rejoin_loyalty_programm").data("options", "action:" + origin + ";");

                    $("#modal-loyalty-refused").foundation('reveal', 'open');
                })
                // CLICK SUR CLASSE AJOUT AU PANIER
                .on('click', '.add-to-cart-common', function (e) {
                    e.preventDefault();

                    var $this = $(e.currentTarget);
                    var reference = $this.data('reference');
                    var variantId = "";

                    if (reference != "undefined") {
                        $.ajax(
                            {
                                url: '/CartAction/AddItemByRef',
                                contentType: "application/json",
                                data: {
                                    reference: reference,
                                    variantId: variantId,
                                },
                                beforeSend: function (e) {
                                    Foundation.libs.common.showPreloader();
                                },
                                success: function (result) {
                                    if (result.IsValid) {
                                        if (typeof (result) != "undefined" && typeof (result._MasterTagJson) != "undefined" && result._MasterTagJson.length > 0) {
                                            $(document).foundation('common', 'refreshMasterTagData', result._MasterTagJson);
                                            $(document).foundation('common', 'refreshMasterTag');
                                        }

                                        if (typeof (tc_vars) !== "undefined" && typeof (tc_vars.order_products) !== "undefined") {
                                            if (variantId === null) {
                                                variantId = "";
                                            }

                                            var order_product = tc_vars.order_products.filter(function (p) {
                                                return p.product_id === reference.toString() && p.product_size === variantId;
                                            })[0];

                                            if (typeof (order_product) !== "undefined") {
                                                var event = {
                                                    obj: "",
                                                    trigger: 'ajout_panier',
                                                    options: {
                                                        id: "ajout_panier",
                                                        category: "ajout produit",
                                                        action: order_product.product_category,
                                                        label: order_product.product_name + '|' + order_product.product_id,
                                                        value: order_product.product_unitprice_ati,
                                                        product_brand: order_product.product_trademark,
                                                        product_cat1: order_product.product_cat1,
                                                        product_cat2: order_product.product_cat2,
                                                        product_cat3: order_product.product_cat3,
                                                        product_category: order_product.product_category,
                                                        product_discount_tf: order_product.product_discount_tf,
                                                        product_discount_ati: order_product.product_discount_ati,
                                                        product_gravable: order_product.product_gravable,
                                                        product_id: order_product.product_id,
                                                        product_name: order_product.product_name,
                                                        product_promo: order_product.product_promo,
                                                        product_promo_label: order_product.product_promo_label,
                                                        product_quantity: order_product.product_quantity,
                                                        product_rating: order_product.product_rating,
                                                        product_size: order_product.product_size,
                                                        product_trademark: order_product.product_trademark,
                                                        product_unitprice_ati: order_product.product_unitprice_ati,
                                                        product_unitprice_tf: order_product.product_unitprice_tf,
                                                    }
                                                };

                                                $(document).foundation('mastertag', 'track_tc_event', event);
                                            }
                                        }

                                        window.location.href = "/cart";
                                    }
                                    else if (result.missingVariantStatus === true) {
                                        // TODO : affficher modal choix variante
                                        //Foundation.libs.cart.missingVariantToAddPopin(result);
                                        Foundation.libs.common.hidePreloader();
                                    }
                                }
                            }
                        ).fail(function (result) {
                            Foundation.libs.common.hidePreloader();
                        });
                    }
                })
                // CLICK SUR LES BOUTONS DE LA MODAL DE CONSENTEMENT (gérée via TagCo)
                .on('click', '#popin_tc_privacy_button_2, #popin_tc_privacy_button_3, #popin_tc_privacy_button', function (e) {
                    setTimeout(() => {
                        const buttonWrapper = document.getElementById("newsletter-button-wrapper");
                        buttonWrapper.classList.remove("-newsletter-hidden");

                        $.ajax({
                            url: '/Navigation/SetCookieNewsletterButtonAnimation',
                        });
                    }, 5000);

                    setTimeout(() => {
                        const pin = document.getElementById("newsletter-pin");
                        pin.classList.remove("-pin-hidden");
                    }, 7000);
                })
                // On supprime la modal une fois fermée sinon s'affiche une deuxième fois
                .on('close.fndtn.reveal', '#newsletter-reveal-modal', () => {
                })
                .on("click", "#newsletter-button", () => {
                    Foundation.libs.reveal.settings.animation_speed = 0;
                    Foundation.libs.reveal.settings.animation = 'pop';
                    $('#newsletter-reveal-modal').foundation('reveal', 'open');
                    Foundation.libs.reveal.settings.animation_speed = 250;
                    Foundation.libs.reveal.settings.animation = 'fadeAndPop';
                })
                // Click sur l'affichage de mot de passe
                .on('click keydown', '.-toggle-password', function (e) {
                    e.preventDefault();
                    var $this = $(e.target);
                    var input = $this.siblings("input")[0];

                    if (input.type === "password") {
                        input.type = "text";
                    } else {
                        input.type = "password";
                    }
                })
                ;
        },
        reflow: function () { },
    };

}(jQuery, window, window.document));

$(document).ready(function () {
    Foundation.libs.common.initCarousel();
    Foundation.libs.lazyloadvideos.initGlobal();
})